<script>
  import { mdiHelpCircle } from "@mdi/js";
  import { defineComponent } from "@vue/composition-api";

  import { appHelpPopover } from "./useAppHelpPopover";

  export default defineComponent({
    name: "app-help-popover",
    props: {
      maxWidth: {
        type: [Number, String],
        default: 400
      },
      icon: {
        type: String,
        default: mdiHelpCircle
      },
      color: {
        type: String,
        default: "info"
      },
      type: {
        type: String,
        default: "info" // can be success, info, warning, error
      },
      dense: {
        type: Boolean,
        default: false
      },
      dark: {
        type: Boolean,
        default: true
      },
      buttonSize: {
        type: String,
        default: "small" // can be small, default, large, x-large
      }
    },
    setup() {
      const { menu } = appHelpPopover();

      return {
        menu
      };
    }
  });
</script>
<template>
  <v-menu
    v-model="menu"
    close-on-content-click
    :nudge-width="200"
    offset-y
    :max-width="maxWidth"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :small="buttonSize === 'small'"
        :large="buttonSize === 'large'"
        :x-large="buttonSize === 'x-large'"
        plain
        v-bind="attrs"
        v-on="on"
      >
        <v-icon :small="buttonSize === 'small'" :color="color">
          {{ icon }}
        </v-icon>
        <slot name="btn-title"></slot>
      </v-btn>
    </template>

    <v-alert :type="type" :dense="dense" :dark="dark" class="ma-0">
      <slot name="help-popover-content"></slot>
    </v-alert>
  </v-menu>
</template>

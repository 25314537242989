import { mdiHome } from "@mdi/js";
import { computed, ref } from "@vue/composition-api";
import { useGet } from "feathers-vuex";
import Vue from "vue";

import { useRouter } from "@/helpers";
const { Project } = Vue.$FeathersVuex.api;
/**
 * Project List
 *
 */
export function useProjectListBreadcrumbs() {
  const breadcrumbs = [
    {
      text: "Home",
      icon: mdiHome,
      disabled: false,
      href: "/dashboards/analytics"
    },
    {
      text: "Projects",
      disabled: true,
      href: "/apps/project/list"
    }
  ];

  return { breadcrumbs };
}

/**
 * Single Project
 *
 */
export function useProjectViewBreadcrumbs() {
  const breadcrumbs = ref([
    {
      text: "Home",
      icon: mdiHome,
      disabled: false,
      href: "/dashboards/analytics"
    },
    {
      text: "Projects",

      disabled: false,
      href: "/apps/project/list"
    }
  ]);

  return { breadcrumbs };
}

/**
 * Contract List
 *
 */
export function useContractListBreadcrumbs() {
  const breadcrumbs = [
    {
      text: "Home",
      icon: mdiHome,
      disabled: false,
      href: "/dashboards/analytics"
    },
    {
      text: "Contracts",
      disabled: true,
      href: "/apps/contract/list"
    }
  ];

  return { breadcrumbs };
}

/**
 * Single Permit
 *
 */
export function usePermitViewBreadcrumbs(project) {
  const breadcrumbs = computed(() => {
    return [
      {
        text: "Home",
        icon: mdiHome,
        disabled: false,
        href: "/dashboards/analytics"
      },
      {
        text: "Projects",

        disabled: false,
        href: "/apps/project/list"
      },
      {
        text: `${project.value.proceed_num}`,

        disabled: false,
        href: `/apps/project/view/${project.value.fulcrum_id}`
      }
    ];
  });

  return { breadcrumbs };
}

/**
 * Single IDR
 * project/:project/construction-record/:record/idr/view/:idr
 */

export function useDailyLogViewBreadcrumbs(dailyLog) {
  const { route } = useRouter();
  const { item: project, hasLoaded: projectHasLoaded } = useGet({
    model: Project,
    id: route.value.params.project
  });

  const breadcrumbs = computed(() => {
    // Start with the guaranteed breadcrumbs
    const crumbs = [
      {
        text: "Home",
        icon: mdiHome,
        disabled: false,
        href: "/dashboards/analytics"
      },
      {
        text: "Projects",
        disabled: false,
        href: "/apps/project/list"
      }
    ];

    // Only add project-specific breadcrumbs if project has loaded
    if (projectHasLoaded.value && project.value) {
      crumbs.push({
        text: `${project.value.proceed_num}`,
        disabled: false,
        href: `/apps/project/view/${route.value.params.project}`
      });
    }

    // Only add permit number if dailyLog exists and has permit_num
    if (dailyLog.value && dailyLog.value.permit_num) {
      crumbs.push({
        text: `Site ${dailyLog.value.permit_num}`,
        disabled: false,
        href: `/apps/project/${route.value.params.project}/construction-record/view/${route.value.params.record}`
      });
    }

    return crumbs;
  });

  return { breadcrumbs, projectHasLoaded };
}

<script>
  //import AppBarNotification from "@/components/base/layouts/components/app-bar/AppBarNotification.vue";
  import {
    mdiAccountHardHat,
    mdiBriefcaseVariantOutline,
    mdiCalendarCheck,
    mdiCardAccountDetails,
    mdiFileDocumentOutline,
    mdiHeartOutline,
    mdiMenu,
    mdiTrafficCone
  } from "@mdi/js";
  import { ref, watch } from "@vue/composition-api";
  import Vue from "vue";

  // Search Data
  import appBarSearchData from "@/assets/app-bar-search-data";
  // App Bar Components
  import AppBarSearch from "@/components/base/layouts/components/app-bar/AppBarSearch.vue";
  //import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
  import AppBarThemeSwitcher from "@/components/base/layouts/components/app-bar/AppBarThemeSwitcher.vue";
  import AppBarUserMenu from "@/components/base/layouts/components/app-bar/AppBarUserMenu.vue";
  import LayoutContentVerticalNav from "@/components/base/layouts/content/LayoutContentVerticalNav.vue";
  import { getVuetify } from "@/helpers";
  //import AppCustomizer from '@/components/base/layouts/components/app-customizer/AppCustomizer.vue'
  import navMenuItems from "@/layouts/content/navigation";

  export default {
    components: {
      LayoutContentVerticalNav,
      //AppCustomizer,

      // App Bar Components
      AppBarSearch,
      //AppBarI18n,
      AppBarThemeSwitcher,
      AppBarUserMenu
      //AppBarNotification
    },
    setup() {
      const $vuetify = getVuetify();

      // Search
      const { Project, ConstructionRecord, DailyLogView } =
        Vue.$FeathersVuex.api;
      const appBarSearchQuery = ref("");
      const shallShowFullSearch = ref(false);
      const searchResults = ref([]);

      // Watch for changes in search query and update results
      watch(appBarSearchQuery, async (newQuery) => {
        if (!newQuery || newQuery.length < 2) {
          searchResults.value = [];
          return;
        }

        try {
          const query = newQuery.toLowerCase();

          // Fetch all results in parallel
          const [projects, permits, idrs] = await Promise.all([
            Project.find({
              query: {
                $or: [
                  { proceed_num: { $iLike: `%${query}%` } },
                  { proceed_desc: { $iLike: `%${query}%` } }
                ],
                $limit: 5
              }
            }),
            ConstructionRecord.find({
              query: {
                $or: [
                  { permit_num: { $iLike: `%${query}%` } },
                  { permit_desc: { $iLike: `%${query}%` } },
                  { project_num: { $iLike: `%${query}%` } },
                  { project_name: { $iLike: `%${query}%` } }
                ],
                $limit: 5
              }
            }),
            DailyLogView.find({
              query: {
                $or: [
                  { location: { $iLike: `%${query}%` } },
                  { permit_num: { $iLike: `%${query}%` } },
                  { proceed_num: { $iLike: `%${query}%` } },
                  { idr_date: { $iLike: `%${query}%` } }
                ],
                $limit: 5
              }
            })
          ]);

          // Format and combine results
          searchResults.value = [
            { header: "Projects" },
            ...projects.data.map((p) => ({
              title: `${p.proceed_num}`,
              subtitle: p.proceed_name,
              type: "project",
              to: {
                name: "apps-project-view",
                params: { project: p.fulcrum_id }
              },
              icon: "mdiBriefcaseVariantOutline"
            })),
            { divider: true },
            { header: "Construction Permits" },
            ...permits.data.map((p) => ({
              title: `${p.permit_num}`,
              subtitle: `${p.permit_desc || ""}`,
              type: "permit",
              to: {
                name: "apps-construction-record-view",
                params: {
                  project: p.project_record,
                  record: p.fulcrum_id
                }
              },
              icon: "mdiTrafficCone"
            })),
            { divider: true },
            { header: "IDRs" },
            ...idrs.data.map((i) => ({
              title: `${i.idr_date}`,
              subtitle: `${i.permit_num} - ${i.location || ""}`,
              type: "idr",
              to: {
                name: "apps-daily-log-view",
                params: {
                  project: i.project_id,
                  record: i.const_id,
                  idr: i.fulcrum_id
                }
              },
              icon: "mdiAccountHardHat",
              meta: i.invoiced ? "Invoiced" : "Not Invoiced"
            }))
          ];
          // If no results in any category, show empty array
          if (
            !projects.data.length &&
            !permits.data.length &&
            !idrs.data.length
          ) {
            searchResults.value = [];
          }
        } catch (error) {
          console.error("Error fetching search results:", error);
          searchResults.value = [];
        }
      });

      // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
      const handleShallShowFullSearchUpdate = (
        isVerticalNavMenuActive,
        toggleVerticalNavMenuActive
      ) => {
        if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
          toggleVerticalNavMenuActive();
        }
      };

      return {
        navMenuItems,
        handleShallShowFullSearchUpdate,

        // Search
        appBarSearchQuery,
        shallShowFullSearch,
        appBarSearchData,
        searchResults,

        icons: {
          mdiAccountHardHat,
          mdiBriefcaseVariantOutline,
          mdiCalendarCheck,
          mdiCardAccountDetails,
          mdiFileDocumentOutline,
          mdiHeartOutline,
          mdiMenu,
          mdiTrafficCone
        }
      };
    }
  };
</script>

<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template
      #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }"
    >
      <div
        class="navbar-content-container"
        :class="{ 'expanded-search': shallShowFullSearch }"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="searchResults"
            :search-query.sync="appBarSearchQuery"
            @update:shallShowFullSearch="
              handleShallShowFullSearchUpdate(
                isVerticalNavMenuActive,
                toggleVerticalNavMenuActive
              )
            "
          ></app-bar-search>
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <!-- <app-bar-i18n></app-bar-i18n> -->
          <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>
          <!--<app-bar-notification></app-bar-notification> -->
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span
          >COPYRIGHT &copy; {{ new Date().getFullYear() }}
          <a href="https://www.deldot.gov" class="text-decoration-none"
            >DelDOT</a
          ><span class="d-none d-md-inline">, All rights Reserved</span></span
        >
        <div class="align-center d-none d-md-flex">
          <span
            >Created and maintained by
            <a href="https://www.centuryeng.com" class="text-decoration-none"
              >Century Engineering, Inc.</a
            >
          </span>
        </div>
      </div>
    </template>

    <template #v-app-content>
      <!-- <app-customizer class="d-none d-md-block"></app-customizer> -->
    </template>
  </layout-content-vertical-nav>
</template>

<style lang="scss" scoped>
  .navbar-content-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    position: relative;
  }

  // ? Handle bg of autocomplete for blured appBar
  .v-app-bar.bg-blur {
    .expanded-search {
      ::v-deep .app-bar-autocomplete-box div[role="combobox"] {
        background-color: transparent;
      }

      > .d-flex > button.v-icon {
        display: none;
      }

      // ===

      & > .right-row {
        visibility: hidden;
        opacity: 0;
      }

      ::v-deep .app-bar-search-toggler {
        visibility: hidden;
      }
    }
  }
</style>

// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";
import hideTestData from "../hooks/hide-test-data";

// Extend the base class
class DailyLogView extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "DailyLogView";
  static instanceDefaults() {
    return {
      fulcrum_id: null,
      const_type: null,
      permit_num: null,
      location: null,
      contract_num: null,
      proceed_num: null,
      idr_date: null,
      contractor_name: null,
      weather: null,
      temperature: null,
      inspector_name: null,
      idr_comment: null,
      item_count: null,
      pers_equip_count: null,
      invoiced: null,
      project_fulcrum_id: null,
      delay_reason: null,
      contractor_id: null,
      inspector_id: null,
      inspector_initials: null,
      inspector_first_name: null,
      inspector_last_name: null,
      const_id: null,
      project_id: null
    };
  }

  static setupInstance(data) {
    // handle null weather data
    if (!data.weather || data.weather === null) {
      data.weather = "---";
    }
  }
}

const servicePath = "daily-logs-view";

const servicePlugin = makeServicePlugin({
  Model: DailyLogView,
  debug: true,
  idField: "fulcrum_id",
  service: feathersClient.service(servicePath),
  servicePath,
  extend: () => {
    return {
      state: {
        pageSkip: 0,
        pageLimit: 25,
        sortField: null,
        isUpdatingPagination: false,
        isEnteringText: false,
        searchText: "",
        isAddingFilter: false,
        isRemovingFilter: false,
        activeFilters: [],
        filterText: null,
        filterIdrDates: [],
        filterContractors: [],
        filterLeadInspectors: [],
        filterInvoiced: false
      },
      mutations: {
        setIsAddingFilter: (state) => {
          state.isAddingFilter = true;
        },
        unsetIsAddingFilter: (state) => {
          state.isAddingFilter = false;
        },
        setIsRemovingFilter: (state) => {
          state.isRemovingFilter = true;
        },
        unsetIsRemovingFilter: (state) => {
          state.isRemovingFilter = false;
        },
        setUpdatePaginationEnter: (state) => {
          state.isUpdatingPagination = true;
        },
        unsetUpdatePaginationEnter: (state) => {
          state.isUpdatingPagination = false;
        },
        setUpdatePagination: (state, payload) => {
          state.sortField = null;
          state.pageSkip = (payload.page - 1) * state.pageLimit;
          state.pageLimit = payload.itemsPerPage;
          state.sortField =
            payload.sortBy.length > 0 ? payload.sortBy[0] : null;
          state.sortDesc = payload.sortDesc[0] == true ? -1 : 1;
        },
        setFilterTextEnter: (state) => {
          state.isFilteringText = true;
        },
        unsetFilterTextEnter: (state) => {
          state.isFilteringText = false;
        },
        setFilterText: (state, payload) => {
          state.filterText = null;
          state.filterText = payload;
          console.log(state.filterText);
        },
        setFilterIdrDates: (state, payload) => {
          state.filterIdrDates = [];
          state.filterIdrDates = payload;
        },
        setFilterContractors: (state, payload) => {
          state.filterContractors = [];
          state.filterContractors = payload;
        },
        setFilterLeadInspectors: (state, payload) => {
          state.filterLeadInspectors = [];
          state.filterLeadInspectors = payload;
        },
        setFilterInvoiced: (state, payload) => {
          state.filterInvoiced = false;
          state.filterInvoiced = payload;
        },
        setClearAllFilters: (state) => {
          state.filterText = null;
          state.filterContractors = [];
          state.filterIdrDates = [];
          state.filterLeadInspectors = [];
          state.filterInvoiced = false;
        }
      },
      actions: {
        initializePagination: (context, payload) => {
          context.commit("setUpdatePaginationEnter");
          context.commit("setUpdatePagination", {
            page: 1,
            itemsPerPage: payload.initialItemsPerPage,
            sortBy: payload.initialSortBy || [],
            sortDesc: [false]
          });
          context.commit("unsetUpdatePaginationEnter");
        },
        updatePagination: (context, payload) => {
          context.commit("setUpdatePaginationEnter");
          context.commit("setUpdatePagination", payload);
          context.commit("unsetUpdatePaginationEnter");
        },
        filterText: (context, payload) => {
          context.commit("setFilterTextEnter");
          context.commit("setFilterText", payload);
          context.commit("unsetFilterTextEnter");
        },
        filterIdrDates: (context, payload) => {
          context.commit("setIsAddingFilter");
          context.commit("setFilterIdrDates", payload);
          context.commit("unsetIsAddingFilter");
        },
        filterContractors: (context, payload) => {
          context.commit("setIsAddingFilter");
          context.commit("setFilterContractors", payload);
          context.commit("unsetIsAddingFilter");
        },
        filterLeadInspectors: (context, payload) => {
          context.commit("setIsAddingFilter");
          context.commit("setFilterLeadInspectors", payload);
          context.commit("unsetIsAddingFilter");
        },
        filterInvoiced: (context, payload) => {
          context.commit("setIsAddingFilter");
          context.commit("setFilterInvoiced", payload);
          context.commit("unsetIsAddingFilter");
        },
        clearAllFilters: (context) => {
          context.commit("setClearAllFilters");
        }
      }
    };
  }
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [hideTestData()],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [
      // (context) => {
      //   let data = context.result;
      //   if (data.idr_photo !== null) {
      //     data.idr_photo = data.idr_photo.split(",");
      //   }
      //   return context;
      // }
    ],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;

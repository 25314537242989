import { mdiCheckboxBlankCircleOutline } from "@mdi/js";

const themeConfig = {
  app: {
    name: "TrafficPM",
    logo: require("@/assets/images/svg/deldot-dark.png"),
    logoDark: require("@/assets/images/svg/deldot.png"),
    isDark: false,
    isRtl: false,
    contentLayoutNav: "vertical", // vertical, horizontal
    routeTransition: "fade-transition", // https://vuetifyjs.com/en/styles/transitions/#api
    // ! `semi-dark` isn't available for horizontal nav menu
    skinVariant: "semi-dark", // default, bordered, semi-dark
    contentWidth: "boxed",
    baseAvatarUrl: process.env.VUE_APP_ASSETS_URL
  },
  menu: {
    isMenuHidden: false,
    isVerticalNavMini: false,
    verticalMenuAccordion: true,
    groupChildIcon: mdiCheckboxBlankCircleOutline,
    horizontalNavMenuGroupOpenOnHover: true
  },
  appBar: {
    /*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
    type: "fixed", // fixed, static, hidden
    isBlurred: true
  },
  footer: {
    type: "static" // fixed, static, hidden
  },
  themes: {
    light: {
      primary: "#2A628F",
      accent: "#FF8200",
      secondary: "#686A6F",
      success: "#56CA00",
      info: "#16B1FF",
      warning: "#FFB400",
      error: "#FF4C51"
    },
    dark: {
      primary: "#2A628F",
      accent: "#FF8200",
      secondary: "#8A8D93",
      success: "#56CA00",
      info: "#16B1FF",
      warning: "#FFB400",
      error: "#FF4C51"
    }
  },
  reporting: {
    tsAgency: "Delaware Department of Transportation",
    tsDept: "Traffic Signal Construction",
    tsTitle: "Traffic Statement"
  }
};

export default themeConfig;

<script>
  import { defineComponent, ref } from "@vue/composition-api";

  //import { useRouter } from "@/helpers";
  import { useProject } from "../../useProject";
  import ConstructionProjectStatusBar from "./ConstructionProjectStatusBar.vue";
  //import { updateProjectStatus } from "./useProjectStatusBar";
  import ProjectStatusSelector from "./ProjectStatusSelector.vue";
  import TrafficStatementStatusBar from "./TrafficStatementStatusBar.vue";

  export default defineComponent({
    components: {
      ProjectStatusSelector,
      TrafficStatementStatusBar,
      ConstructionProjectStatusBar
    },
    setup() {
      //const { route } = useRouter();
      const { project: projectRecord, projectHasLoaded } = useProject();
      const key = ref(0);
      const overlayIsVisible = ref(false);

      function forceRerender() {
        key.value = key.value + 1;
      }

      function showOverlay(value) {
        overlayIsVisible.value = value;
      }

      return {
        key,
        overlayIsVisible,
        showOverlay,
        forceRerender,
        projectRecord,
        projectHasLoaded
      };
    }
  });
</script>

<template>
  <v-card v-if="projectHasLoaded" flat :key="key" class="mb-7">
    <v-card-title>
      <p class="mb-2 mb-sm-0">Project Milestones</p>
      <v-spacer></v-spacer>
      <!-- Status Change Menu -->
      <project-status-selector
        :project-phase="projectRecord.project_phase"
        :project-id="projectRecord.fulcrum_id"
        @show-overlay="showOverlay"
        @force-rerender="forceRerender"
      />
    </v-card-title>
    <v-card-text>
      <traffic-statement-status-bar
        v-if="projectRecord.project_phase === 'Design'"
        :project-status="projectRecord.status"
      />
      <construction-project-status-bar
        v-if="projectRecord.project_phase === 'Construction'"
        :project-status="projectRecord.status"
      />
    </v-card-text>
    <v-overlay v-model="overlayIsVisible" absolute color="white" opacity="0.75">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

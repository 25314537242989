import { onMounted, ref, watch } from "@vue/composition-api";

import { store } from "@/store";

export function usePagination(options = {}) {
  const {
    initialPage = 1,
    initialItemsPerPage = 10,
    initialSortBy = [],
    initialSortDesc = [],
    itemsPerPageOptions = [5, 10, 25, 50],
    storePath = null // Add store path as an optional parameter
  } = options;

  const paginationOptions = ref({
    page: initialPage,
    itemsPerPage: initialItemsPerPage,
    sortBy: initialSortBy,
    sortDesc: initialSortDesc
  });

  // If storePath is provided, watch for changes and update store
  if (storePath) {
    // Initialize store when component mounts
    onMounted(() => {
      store.dispatch(`${storePath}/initializePagination`, {
        initialItemsPerPage,
        initialSortBy,
        initialSortDesc
      });
    });

    watch(
      () => paginationOptions.value,
      (newOptions) => {
        store.dispatch(`${storePath}/updatePagination`, newOptions);
      },
      { deep: true }
    );
  }
  return {
    options: paginationOptions,
    itemsPerPageOptions
  };
}

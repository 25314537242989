import { mdiFileDocumentEditOutline, mdiHome } from "@mdi/js";
import { computed } from "@vue/composition-api";
import { useFind } from "feathers-vuex";
import Vue from "vue";

import { store } from "@/store";
const { Contract } = Vue.$FeathersVuex.api;

export function useContractList() {
  const contractsParams = computed(() => {
    let q = {
      query: {
        $limit: store.state["contracts"].pageLimit || 25,
        $skip: store.state["contracts"].pageSkip,
        status: {
          $in:
            store.state["contracts"].contractStatus &&
            store.state["contracts"].contractStatus.length > 0
              ? store.state["contracts"].contractStatus
              : store.state["contracts"].contractStatus
        },
        contract_type: {
          $in:
            store.state["contracts"].contractType &&
            store.state["contracts"].contractType.length > 0
              ? store.state["contracts"].contractType
              : store.state["contracts"].contractType
        },
        $sort: {
          portal_updated_at: -1
        }
      }
    };

    /**
     * If a user enters text in the search text box then we need
     * to do a fuzzy search across 3 different fields:
     *    - contract_num
     *    - fap_num
     *    - contract_desc
     */
    /********************** */

    if (store.state["contracts"].filterText !== null) {
      q.query.$and = [
        {
          $or: [
            {
              contract_num: {
                $iLike: "%" + store.state["contracts"].filterText + "%"
              }
            },
            {
              fap_num: {
                $iLike: store.state["contracts"].filterText + "%"
              }
            },
            {
              contract_desc: {
                $iLike: "%" + store.state["contracts"].filterText + "%"
              }
            }
          ]
        }
      ];
    }

    if (store.state["contracts"].sortField !== null) {
      q.query.$sort = {
        [store.state["contracts"].sortField]: store.state["contracts"].sortDesc
      };
    }

    return q;
  });

  const {
    items: contracts,
    isPending: isContractLoading,
    haveLoaded,
    paginationData,
    latestQuery,
    find: findContracts
  } = useFind({
    model: Contract,
    params: contractsParams,
    paginate: true,
    qid: "contractList"
  });

  return {
    contracts,
    isContractLoading,
    haveLoaded,
    paginationData,
    latestQuery,
    findContracts,
    contractsParams
  };
}

export function useContractDatatable() {
  // const componentKey = ref(0);
  // Data Table headers
  const headers = [
    {
      text: "CONTRACT NUMBER",
      align: "start",
      value: "contract_num",
      sortable: true
    },
    { text: "#FAP", value: "fap_num", sortable: false },
    { text: "DESCRIPTION", value: "contract_desc", sortable: false },
    { text: "CONTRACT TYPE", value: "contract_type", sortable: false },
    { text: "STATUS", value: "status", sortable: false },
    { text: "ACTIONS", align: "center", value: "actions", sortable: false }
  ];

  // const forceRerender = (componentKey) => {
  //   return (componentKey += 1);
  // };

  return {
    headers
    // componentKey,
    // forceRerender
  };
}

export function useContractListBreadcrumbs() {
  const breadcrumbs = [
    {
      text: "Home",
      icon: mdiHome,
      disabled: false,
      href: "/dashboards/analytics"
    },
    {
      text: "Contracts",
      icon: mdiFileDocumentEditOutline,
      disabled: true,
      href: "/apps/contract/list"
    }
  ];

  return { breadcrumbs };
}

<script>
  import {
    mdiAccountHardHat,
    mdiClipboardCheckOutline,
    mdiClipboardPulseOutline,
    mdiCurrencyUsd
  } from "@mdi/js";
  import { defineComponent, ref } from "@vue/composition-api";

  // components
  import Breadcrumbs from "@/components/common/breadcrumbs/Breadcrumbs.vue";
  import { usePermitViewBreadcrumbs } from "@/components/common/breadcrumbs/useBreadcrumbs";
  import { useRouter } from "@/helpers";

  import { useConstructionRecord } from "../useConstructionRecord";
  import ConstructionRecordInfoPanel from "./construction-record-info-panel/ConstructionRecordInfoPanel.vue";
  import ConstructionRecordTabFinancial from "./construction-record-tab-financial/ConstructionRecordTabFinancial.vue";
  import ConstructionRecordTabIdrs from "./construction-record-tab-idrs/ConstructionRecordTabIdrs.vue";
  import ConstructionRecordTabItemTracking from "./construction-record-tab-item-tracking/ConstructionRecordTabItemTracking.vue";
  import ConstructionRecordTabOverview from "./construction-record-tab-overview/ConstructionRecordTabOverview.vue";
  export default defineComponent({
    components: {
      Breadcrumbs,
      ConstructionRecordInfoPanel,
      ConstructionRecordTabOverview,
      ConstructionRecordTabFinancial,
      ConstructionRecordTabIdrs,
      ConstructionRecordTabItemTracking
    },
    setup() {
      const { route } = useRouter();
      const projectRecord = route.value.params.project;

      //const { project, projectHasLoaded } = useProject();

      const {
        project,
        projectHasLoaded,
        constructionRecord,
        constructionRecordHasLoaded,
        isLoaded
      } = useConstructionRecord();
      const { breadcrumbs } = usePermitViewBreadcrumbs(project);
      const permitTab = ref(null);
      const tabs = [
        { icon: mdiClipboardCheckOutline, title: "Overview" },
        { icon: mdiCurrencyUsd, title: "Financial" },
        { icon: mdiAccountHardHat, title: "IDRs" },
        { icon: mdiClipboardPulseOutline, title: "Item Tracking" }
      ];
      return {
        projectRecord,
        breadcrumbs,
        project,
        projectHasLoaded,
        constructionRecord,
        constructionRecordHasLoaded,
        isLoaded,
        permitTab,
        tabs,
        route
      };
    }
  });
</script>

<template>
  <div :key="route.params.record">
    <div v-if="isLoaded" id="user-view">
      <breadcrumbs
        v-if="projectHasLoaded"
        class="mb-5"
        :items="breadcrumbs"
        :active-link="{
          text: `Site ${constructionRecord.permit_num}`,
          disabled: true,
          href: `/apps/project/${projectRecord}/permit/view/`
        }"
      />
      <v-row>
        <v-col cols="12" md="4" lg="4">
          <construction-record-info-panel
            v-if="constructionRecordHasLoaded"
            :construction-record="constructionRecord"
          />
        </v-col>
        <v-col cols="12" md="8" lg="8">
          <!-- <project-status-bar class="mb-5" /> -->
          <v-tabs v-model="permitTab" show-arrows class="user-tabs">
            <v-tab v-for="tab in tabs" :key="tab.icon">
              <v-icon size="20" class="me-3">
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items
            id="user-tabs-content"
            v-model="permitTab"
            class="mt-5 pa-1"
          >
            <!-- Project Overview Tab -->
            <v-tab-item>
              <construction-record-tab-overview
                v-if="constructionRecordHasLoaded"
                :construction-record="constructionRecord"
              />
            </v-tab-item>

            <!-- Financial Overview Tab -->
            <v-tab-item>
              <construction-record-tab-financial />
            </v-tab-item>

            <!-- IDRs Tab -->
            <v-tab-item>
              <construction-record-tab-idrs />
            </v-tab-item>

            <!-- Item Tracking Tab -->
            <v-tab-item>
              <construction-record-tab-item-tracking />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style lang="scss">
  @import "@/preset/preset/apps/user.scss";
</style>

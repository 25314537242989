<script>
  import { mdiCheckDecagram, mdiFileDocumentEditOutline } from "@mdi/js";
  import { defineComponent, watch } from "@vue/composition-api";

  import { usePagination } from "@/composables/usePagination";
  import { permitTypeIcon } from "@/composables/usePermitTypes.js";
  import { useRouter } from "@/helpers";
  import { permitStatusColor } from "@/helpers/statusColors.js";
  import { store } from "@/store";
  import {
    useAssignConstructionRecordDialog,
    useConstructionRecordListById,
    useSelectedPermitDatatable
  } from "@/views/apps/construction-record/useConstructionRecord.js";
  import { getProjectFromStore } from "@/views/apps/project/useProject";

  import ConstructionRecordListEdit from "./ConstructionRecordListEdit.vue";
  import ConstructionRecordMissingDataAlert from "./ConstructionRecordMissingDataAlert.vue";

  export default defineComponent({
    components: {
      ConstructionRecordListEdit,
      ConstructionRecordMissingDataAlert
    },
    setup() {
      const { route } = useRouter();
      const { projectRecord } = getProjectFromStore(route.value.params.project);
      const {
        constructionRecords,
        constructionRecordsHaveLoaded,
        areConstructionRecordsPending,
        latestConstructionRecordsQuery
      } = useConstructionRecordListById();

      const tableHeaders = [
        { text: "PERMIT NUMBER", value: "permit_num", sortable: false },
        { text: "STATUS", value: "status", sortable: false },
        { text: "DESCRIPTION", value: "permit_desc", sortable: false },
        {
          text: "ACTIONS",
          value: "actions",
          align: "center",
          sortable: false
        }
      ];

      const { options, itemsPerPageOptions } = usePagination({
        initialItemsPerPage: 25,
        initialSortBy: ["permit_num"],
        itemsPerPageOptions: [5, 10, 25, 50],
        storePath: "construction-records"
      });

      const {
        isPermitListEditDialogOpen,
        itemsToEdit,
        editItems,
        updateSelectedConstructionRecords,
        removeConstructionRecord,
        processFormData,
        closeAssignPermitDialog
      } = useAssignConstructionRecordDialog();

      const { selectedPermits, setSelectedPermits } =
        useSelectedPermitDatatable();

      watch(options, (value) => {
        store.dispatch("construction-records/updatePagination", value);
      });

      return {
        projectRecord,
        constructionRecords,
        constructionRecordsHaveLoaded,
        areConstructionRecordsPending,
        latestConstructionRecordsQuery,
        tableHeaders,
        options,
        itemsPerPageOptions,
        isPermitListEditDialogOpen,
        itemsToEdit,
        editItems,
        processFormData,
        closeAssignPermitDialog,
        selectedPermits,
        setSelectedPermits,
        permitTypeIcon,
        permitStatusColor,
        updateSelectedConstructionRecords,
        removeConstructionRecord,
        icons: {
          mdiFileDocumentEditOutline,
          mdiCheckDecagram
        }
      };
    }
  });
</script>
<template>
  <div v-if="constructionRecordsHaveLoaded" class="user-tab-overview">
    <construction-record-missing-data-alert />
    <!-- associated permits list -->
    <v-card flat class="mb-7">
      <v-card-title class="d-flex align-center justify-space-between flex-wrap">
        <div class="d-flex">Associated Permits</div>
        <div v-if="projectRecord.project_phase === 'Design'" class="d-flex">
          <v-btn
            color="primary"
            class="me-3"
            x-small
            outlined
            @click="editItems(constructionRecords)"
          >
            Assign Permit
          </v-btn>
        </div>
      </v-card-title>
      <v-data-table
        :headers="tableHeaders"
        :items="latestConstructionRecordsQuery.response.data"
        :options.sync="options"
        :server-items-length="latestConstructionRecordsQuery.response.total"
        :loading="areConstructionRecordsPending"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25, 50]
        }"
      >
        <!-- permit -->
        <template #[`item.permit_num`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              v-if="item.permit_type"
              :color="item.permit_type ? '' : 'primary'"
              :class="item.permit_type ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-icon dense>{{ permitTypeIcon(item.permit_type[0]) }}</v-icon>
            </v-avatar>
            <div v-if="item.fulcrum_id" class="d-flex flex-column ms-3">
              <span v-if="projectRecord.project_phase === 'Construction'">
                <router-link
                  :to="{
                    name: 'apps-construction-record-view',
                    params: {
                      project: item.project_record,
                      record: item.fulcrum_id
                    }
                  }"
                  class="primary--text font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                >
                  {{ item.permit_num }}
                </router-link>
              </span>
              <span v-else class="font-weight-semibold">
                {{ item.permit_num }}
              </span>
            </div>
          </div>
        </template>

        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="permitStatusColor(item.status)"
            :class="`${permitStatusColor(item.status)}--text`"
            class="v-chip-light-bg"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <!-- description -->
        <template #[`item.permit_desc`]="{ item }">
          {{ item.permit_desc }}
        </template>

        <!-- action -->
        <!-- NOTE: The v-slot modifier is NOT an error -->
        <template v-slot:item.actions="{ item }">
          <router-link
            v-if="
              projectRecord.project_phase === 'Design' &&
              projectRecord.contractor_record !== null &&
              projectRecord.short_list_record !== null &&
              projectRecord.contractor_list_record !== null
            "
            :to="{
              name: 'apps-construction-record-edit',
              params: {
                project: item.project_record,
                record: item.fulcrum_id
              }
            }"
            class="primary--text font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            <v-icon class="mr-2" color="primary" dense>
              {{ icons.mdiFileDocumentEditOutline }}
            </v-icon>
          </router-link>
          <span
            v-else-if="
              (projectRecord.project_phase === 'Design' &&
                projectRecord.contractor_record == null) ||
              projectRecord.short_list_record == null ||
              projectRecord.contractor_list_record == null
            "
          >
            <v-icon class="mr-2" color="secondary" dense>
              {{ icons.mdiFileDocumentEditOutline }}
            </v-icon>
          </span>
          <span v-else-if="projectRecord.project_phase === 'Construction'">
            <v-icon class="mr-2" color="secondary" dense>
              {{ icons.mdiFileDocumentEditOutline }}
            </v-icon>
          </span>
          <span v-if="item.status == 'TS Complete'">
            <v-icon class="mr-2" color="success" dense>
              {{ icons.mdiCheckDecagram }}
            </v-icon>
          </span>
        </template>
      </v-data-table>

      <!-- assign permit dialog -->
      <construction-record-list-edit
        :is-permit-list-edit-dialog-open.sync="isPermitListEditDialogOpen"
        :edit-items="itemsToEdit"
        @save="processFormData"
        @update="updateSelectedConstructionRecords"
        @remove="removeConstructionRecord"
        @close-dialog="closeAssignPermitDialog"
      ></construction-record-list-edit>
    </v-card>
  </div>
</template>

<script>
  import { mdiChevronLeft, mdiEmailOutline } from "@mdi/js";
  import { defineComponent, ref } from "@vue/composition-api";
  // Using VeeValidate for validations
  import { ValidationObserver, ValidationProvider } from "vee-validate";

  import { snackbar } from "@/components/base/snackbar/useSnackbar";
  import { useRouter } from "@/helpers";
  import { store } from "@/store";
  import themeConfig from "@/themeConfig";

  import AppHelpPopover from "../../components/base/help-popover/AppHelpPopover.vue";

  export default defineComponent({
    components: {
      AppHelpPopover,
      ValidationProvider,
      ValidationObserver
    },
    setup() {
      const { router } = useRouter();
      const form = ref(null);
      const email = ref(null);
      const loading = ref(false);

      const handleFormSubmit = (email) => {
        loading.value = true;
        let options = {
          action: "sendResetPwd",
          value: { email: email }
        };
        store
          .dispatch("authManagement/create", options)
          .then(() => {
            snackbar({
              text: "Please check your email for further instructions.",
              color: "success"
            });
            loading.value = false;
            router.push({ name: "account-login" });
          })
          .catch((error) => {
            snackbar({
              text: error,
              color: "error"
            });
            loading.value = false;
          });
      };
      return {
        form,
        email,
        loading,
        icons: {
          mdiChevronLeft,
          mdiEmailOutline
        },
        // themeConfig
        appName: themeConfig.app.name,
        appLogo: themeConfig.app.logoDark,
        handleFormSubmit
      };
    }
  });
</script>

<template>
  <validation-observer ref="form" v-slot="{ invalid }">
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card class="auth-card">
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link to="/" class="d-flex align-center">
              <v-img
                :src="appLogo"
                max-height="30px"
                max-width="30px"
                alt="logo"
                contain
                class="me-3"
              ></v-img>

              <h2 class="text-2xl font-weight-semibold">
                {{ appName }}
              </h2>
            </router-link>
          </v-card-title>
          <v-card-text>
            <p
              class="text-xl font-weight-semibold text--primary text-center mb-2"
            >
              Forgot Password? 🔒
            </p>
            <p class="mb-2">
              Enter your email and we'll send you instructions to reset your
              password
            </p>
          </v-card-text>
          <v-card-text>
            <form @submit.prevent="handleFormSubmit(email)">
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                rules="required|email"
              >
                <label
                  for="email"
                  class="d-flex justify-space-between align-center font-weight-semibold"
                >
                  Email
                  <app-help-popover :max-width="500">
                    <template v-slot:btn-title>
                      <span class="info--text font-weight-semibold">
                        Important Email Note
                      </span>
                    </template>
                    <template v-slot:help-popover-content>
                      <span class="font-weight-normal">
                        Please enter your email exactly as it was registered.
                        Email addresses are case-sensitive, so
                        <span class="font-italic font-weight-medium">
                          "User@example.com"
                        </span>
                        and
                        <span class="font-italic font-weight-medium">
                          "user@example.com"
                        </span>
                        are treated as different addresses.
                      </span>
                    </template>
                  </app-help-popover>
                </label>
                <v-text-field
                  id="email"
                  v-model="email"
                  dense
                  outlined
                  clearable
                  :prepend-inner-icon="icons.mdiEmailOutline"
                  placeholder="john@example.com"
                  class="mb-4"
                  required
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
              <v-btn
                type="submit"
                block
                color="primary"
                :loading="loading"
                :disabled="invalid"
              >
                Send reset link
              </v-btn>
            </form>
          </v-card-text>
          <v-card-actions class="d-flex justify-center align-center">
            <router-link
              :to="{ name: 'account-login' }"
              class="d-flex align-center text-sm"
            >
              <v-icon size="24" color="primary">
                {{ icons.mdiChevronLeft }}
              </v-icon>
              <span>Back to login</span>
            </router-link>
          </v-card-actions>
        </v-card>
      </div>
      <!-- background triangle shape  -->
      <img
        class="auth-mask-bg"
        height="190"
        :src="
          require(`@/assets/images/misc/mask-${
            $vuetify.theme.dark ? 'dark' : 'light'
          }.png`)
        "
      />

      <!-- tree -->
      <v-img
        class="auth-tree"
        width="247"
        height="185"
        src="@/assets/images/misc/tree.png"
      ></v-img>

      <!-- tree  -->
      <v-img
        class="auth-tree-3"
        width="377"
        height="289"
        src="@/assets/images/misc/tree-3.png"
      ></v-img>
    </div>
  </validation-observer>
</template>

<style lang="scss">
  @import "@/preset/preset/pages/auth.scss";
</style>

// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";
// import { paramsForServer } from "feathers-hooks-common";

// Extend the base class
class PermitItemFinancial extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "PermitItemFinancial";
  static instanceDefaults() {
    return {
      estimate_item_id: null,
      const_id: null,
      project_id: null,
      status: null,
      item_num: null,
      item_desc: null,
      item_unit: null,
      actual_qty: null,
      estimate_qty: null,
      remaining_qty: null,
      item_cost: null,
      actual_cost: null,
      estimate_cost: null,
      remaining_cost: null,
      remaining_percent: null
    };
  }
}

const servicePath = "permit-item-financials";

const servicePlugin = makeServicePlugin({
  debug: true,
  Model: PermitItemFinancial,
  idField: "item_num",
  service: feathersClient.service(servicePath),
  servicePath,
  extend: () => {
    return {
      state: {
        pageSkip: 0,
        pageLimit: 10,
        sortField: null,
        sortOrder: 1,
        isUpdatingPagination: false,
        isEnteringText: false,
        filterText: null,
        isEnteringRange: false,
        filterPercentRemainRange: [0, 0],
        isEnteringOverUnder: false,
        filterOverUnderEstimate: null,
        isEnteringItemStatus: false,
        filterItemStatuses: []
      },
      mutations: {
        setUpdatePaginationEnter: (state) => {
          state.isUpdatingPagination = true;
        },
        unsetUpdatePaginationEnter: (state) => {
          state.isUpdatingPagination = false;
        },
        setUpdatePagination: (state, payload) => {
          state.sortField = null;
          state.pageSkip = (payload.page - 1) * state.pageLimit;
          state.pageLimit = payload.itemsPerPage;
          state.sortField =
            payload.sortBy.length > 0 ? payload.sortBy[0] : null;
          state.sortDesc = payload.sortDesc[0] == true ? -1 : 1;
        },
        setFilterTextEnter: (state) => {
          state.isFilteringText = true;
        },
        unsetFilterTextEnter: (state) => {
          state.isFilteringText = false;
        },
        setFilterText: (state, payload) => {
          state.filterText = null;
          state.filterText = payload;
          console.log(state.filterText);
        },
        setRangeEnter: (state) => {
          state.isEnteringRange = true;
        },
        setRemainRange: (state, payload) => {
          state.filterPercentRemainRange = [0, 0];
          state.filterPercentRemainRange = payload;
        },
        unsetRangeEnter: (state) => {
          state.isEnteringRange = false;
        },
        setOverUnderEnter: (state) => {
          state.isEnteringOverUnder = true;
        },
        setOverUnder: (state, payload) => {
          state.filterOverUnderEstimate = null;
          state.filterOverUnderEstimate = payload;
        },
        unsetOverUnderEnter: (state) => {
          state.isEnteringOverUnder = false;
        },
        setItemStatusesEnter: (state) => {
          state.isEnteringItemStatus = true;
        },
        setItemStatuses: (state, payload) => {
          state.filterItemStatuses = [];
          state.filterItemStatuses = payload;
        },
        unsetItemStatusesEnter: (state) => {
          state.isEnteringItemStatus = false;
        },
        setClearAllFilters: (state) => {
          state.filterText = null;
          state.filterPercentRemainRange = [0, 0];
          state.filterOverUnderEstimate = null;
          state.filterItemStatuses = [];
        }
      },
      actions: {
        initializePagination: (context, payload) => {
          context.commit("setUpdatePaginationEnter");
          context.commit("setUpdatePagination", {
            page: 1,
            itemsPerPage: payload.initialItemsPerPage,
            sortBy: payload.initialSortBy || [],
            sortDesc: [false]
          });
          context.commit("unsetUpdatePaginationEnter");
        },
        updatePagination: (context, payload) => {
          context.commit("setUpdatePaginationEnter");
          context.commit("setUpdatePagination", payload);
          context.commit("unsetUpdatePaginationEnter");
        },
        filterText: (context, payload) => {
          context.commit("setFilterTextEnter");
          context.commit("setFilterText", payload);
          context.commit("unsetFilterTextEnter");
        },
        updatePercentRemainRange: (context, payload) => {
          context.commit("setRangeEnter");
          context.commit("setRemainRange", payload);
          context.commit("unsetRangeEnter");
        },
        updateOverUnderEstimate: (context, payload) => {
          context.commit("setOverUnderEnter");
          context.commit("setOverUnder", payload);
          context.commit("unsetOverUnderEnter");
        },
        updateItemStatuses: (context, payload) => {
          context.commit("setItemStatusesEnter");
          context.commit("setItemStatuses", payload);
          context.commit("unsetItemStatusesEnter");
        },
        clearAllFilters: (context) => {
          context.commit("setClearAllFilters");
        }
      }
    };
  }
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;

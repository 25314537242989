import { computed, getCurrentInstance, ref } from "@vue/composition-api";
import { useFind, useGet } from "feathers-vuex";
import Vue from "vue";

import { snackbar } from "@/components/base/snackbar/useSnackbar";
import { useRouter } from "@/helpers";
import { store } from "@/store";
const { ConstructionRecord, EstimateItem, Permit, Project } =
  Vue.$FeathersVuex.api;

export function useConstructionRecordListById() {
  const { route } = useRouter();
  const constructionRecordsParams = computed(() => {
    return {
      query: {
        $select: [
          "fulcrum_id",
          "project_record",
          "permit_record",
          "project_num",
          "permit_num",
          "permit_desc",
          "permit_type",
          "status"
        ],
        project_record: route.value.params.project,
        $limit: store.state["construction-records"].pageLimit || 25,
        $skip: store.state["construction-records"].pageSkip,
        $sort: {
          permit_num: 1
        }
      },
      $graphql: {
        idrs: false,
        work_delays: false
      }
    };
  });

  const {
    items: constructionRecords,
    isPending: areConstructionRecordsPending,
    haveLoaded: constructionRecordsHaveLoaded,
    paginationData,
    latestQuery: latestConstructionRecordsQuery
  } = useFind({
    model: ConstructionRecord,
    params: constructionRecordsParams,
    paginate: true,
    qid: "constructionRecordsList"
  });

  return {
    constructionRecords,
    areConstructionRecordsPending,
    constructionRecordsHaveLoaded,
    paginationData,
    latestConstructionRecordsQuery
  };
}

export function getConstructionRecordCount() {
  const constructionRecordCount = ref(0);

  try {
    let params = { query: {} };
    store.dispatch("construction-records/count", params).then((response) => {
      constructionRecordCount.value = response;
    });
  } catch (error) {
    console.log(error);
  }

  return {
    constructionRecordCount
  };
}

export function getConstructionRecordFromStore(record) {
  const constructionRecord = ConstructionRecord.getFromStore(record);
  console.log("This is the constructionRecord: ", constructionRecord);
  return {
    constructionRecord
  };
}

export function useConstructionRecord() {
  const { route } = useRouter();
  const recordId = computed(() => route.value.params.record);
  const projectId = computed(() => route.value.params.project);

  // Get project data first
  const { item: project, hasLoaded: projectHasLoaded } = useGet({
    model: Project,
    id: projectId,
    params: {
      $graphql: {
        designer_nm: true,
        project_phase: true,
        contractor_nm: true,
        design_firm_nm: true,
        inspector_nm: true,
        contract_num: true,
        budget_remaining: true,
        site_record: true
      }
    }
  });

  const constructionRecordParams = computed(() => ({
    query: {},
    $graphql: {
      idr_count: true,
      work_delay_count: true,
      logged_items_count: true
    }
  }));

  const { item: constructionRecord, hasLoaded: constructionRecordHasLoaded } =
    useGet({
      model: ConstructionRecord,
      id: recordId,
      params: constructionRecordParams
    });

  // Computed property to check if everything is loaded
  const isLoaded = computed(
    () =>
      projectHasLoaded.value &&
      constructionRecordHasLoaded.value &&
      project.value !== null &&
      constructionRecord.value !== null
  );

  return {
    project,
    projectHasLoaded,
    constructionRecord,
    constructionRecordHasLoaded,
    isLoaded
  };
}

export function usePermitListLookup() {
  const search = ref(null);
  const permitsParams = computed(() => {
    let q = {};
    // let q = {
    //   query: {
    //     $limit: store.state["permits"].pageLimit,
    //     $skip: store.state["permits"].pageSkip,
    //     $select: ["fulcrum_id", "permit_id", "permit_desc", "permit_type"],
    //     $sort: {
    //       permit_id: 1
    //     }
    //   }
    // };
    //let permit_id = {};
    if (store.state["permits"].filterText !== null) {
      q = {
        query: {
          $limit: store.state["permits"].pageLimit,
          //$skip: store.state["permits"].pageSkip,
          $select: ["fulcrum_id", "permit_id", "permit_desc", "permit_type"],
          permit_id: {
            $regex: store.state["permits"].filterText + "%"
          },
          $sort: {
            permit_id: 1
          }
        }
      };
    } else {
      q = {
        query: {
          $limit: store.state["permits"].pageLimit,
          //$skip: store.state["permits"].pageSkip,
          $select: ["fulcrum_id", "permit_id", "permit_desc", "permit_type"],
          permit_id: {
            $ne: null
          },
          $sort: {
            permit_id: 1
          }
        }
      };
    }
    //let $or = [];
    // if (store.state["permits"].filterText !== null) {
    //   q.query.$or.push({
    //     permit_id: { $iLike: `${store.state["permits"].filterText}%` }
    //   });
    //   q.query.$or.push({
    //     permit_desc: { $iLike: `%${store.state["permits"].filterText}%` }
    //   });
    // } else {
    //   q.query.$or.push({ permit_id: { $ne: null } });
    //   q.query.$or.push({ permit_desc: { $ne: null } });
    // }

    //Object.assign(q.query, { permit_id });
    return q;
  });
  const {
    items: permits,
    isPending: arePermitsPending,
    haveLoaded: permitsHaveLoaded,
    latestQuery: latestPermitsQuery
  } = useFind({
    model: Permit,
    params: permitsParams,
    paginate: false,
    qid: "permitsListLookup"
  });

  return {
    search,
    permits,
    arePermitsPending,
    permitsHaveLoaded,
    latestPermitsQuery
  };
}

export function useAssignPermitDatatable() {
  const headers = [
    { text: "PERMIT TYPE", value: "permit_type", sortable: false },
    { text: "PERMIT ID", value: "permit_id", sortable: false },
    { text: "DESCRIPTION", value: "permit_desc", sortable: false }
  ];

  return {
    headers
  };
}

/**
 * DataTable for displaying permits that are being assigned to a project.
 * Used for adding and editing a project.
 *
 */
export function useSelectedPermitDatatable() {
  const selectedPermits = ref([]);
  const { emit } = getCurrentInstance();
  const headers = [
    { text: "PERMIT", value: "permit_id" },
    {
      text: "ACTIONS",
      value: "actions",
      align: "center",
      sortable: false
    }
  ];

  // We need to push the selected permits into the Construction Records schema so that
  // we can differentiate between an existing construction record and a brand new
  // construction record. The difference between the two is that an existing construction
  // record will have a `fulcrum_id` whereas a new one will not. This distinction is
  // important because we need to filter out existing construction records so they aren't
  // added to the construction records table and we need to append the construction record
  // `fulcrum_id` to the `site_record` array that's in the `proceed.project` table.
  const setSelectedPermits = (permits) => {
    selectedPermits.value = [...permits];
    console.log("selected permits");
    console.log(selectedPermits.value);
    // return selectedPermits;
    emit("update", selectedPermits.value);
  };

  const removePermit = (item) => {
    console.log(item);
    selectedPermits.value = selectedPermits.value.filter(
      (i) => i.permit_id !== item.permit_id
    );
    emit("update", selectedPermits.value);
  };

  return {
    selectedPermits,
    setSelectedPermits,
    headers,
    removePermit
  };
}

/**
 * Add a new Construction Record to the Project. We will need to grab the project's
 * `fulcrum_id` and add it to each permit that was selected.
 *  We must transform:
 *      [
 *        {
 *          fulcrum_id: "<something>",
 *          permit_desc: "<something>",
 *          permit_id: "<something>",
 *          permit_type: "<need to fix to display all elements of array>"
 *        }
 *      ]
 *
 *  To the Construction Record model
 */
export function useAssignConstructionRecordDialog() {
  const loading = ref(false);
  let isPermitListEditDialogOpen = ref(false);
  let isUpdatingPermitAssignment = ref(false);
  let itemsToEdit = ref([]);
  let itemsForRemoval = ref([]);
  const selectedPermits = ref([]);

  const tableHeaders = [
    { text: "PERMIT", value: "permit_id" },
    {
      text: "ACTIONS",
      value: "actions",
      align: "center",
      sortable: false
    }
  ];

  const editItems = (items) => {
    itemsToEdit.value = [...items];
    isPermitListEditDialogOpen.value = true;
  };

  const updateSelectedConstructionRecords = (selectedPermits) => {
    let updatedRecords = selectedPermits.map((permit) => ({
      fulcrum_id: null,
      project_record: permit.project_record ? permit.project_record : null,
      permit_record: permit.fulcrum_id,
      project_num: permit.project_id ? permit.project_id : null,
      project_name: permit.project_name ? permit.project_name : null,
      permit_num: permit.permit_id,
      permit_desc: permit.permit_desc,
      permit_type: permit.permit_type,
      status: "TS Not Started"
    }));
    updatedRecords.forEach((record) => {
      let index = itemsToEdit.value.findIndex(
        (item) => item.permit_record == record.permit_record
      );
      if (index === -1) {
        itemsToEdit.value.push(record);
      }
    });
  };

  const removeConstructionRecord = (item) => {
    itemsToEdit.value = itemsToEdit.value.filter(
      (i) => i.permit_record !== item.permit_record
    );
    // only add to array if there's a `fulcrum_id`
    if (item.fulcrum_id !== null) {
      itemsForRemoval.value.push(item);
    }
  };

  const processFormData = async (form) => {
    try {
      loading.value = true;
      let editItems = form.editItems;

      let constructionRecords = editItems.map((editItem) => ({
        fulcrum_id: editItem.fulcrum_id ? editItem.fulcrum_id : null,
        project_record: form.project_record ? form.project_record : null,
        permit_record: editItem.permit_record,
        project_num: form.project_num ? form.project_num : null,
        project_name: form.project_name ? form.project_name : null,
        permit_num: editItem.permit_num,
        permit_desc: editItem.permit_desc,
        permit_type: editItem.permit_type.join(),
        status: editItem.status
      }));

      let clonedConstructionRecords = ref([]);
      constructionRecords.forEach((record) => {
        const constructionRecord = new ConstructionRecord({});
        const constructionRecordClone = constructionRecord.clone();
        Object.assign(constructionRecordClone, record);
        clonedConstructionRecords.value.push(constructionRecordClone);
      });

      // We will send individual records to the server.
      // We only need to send records that don't have a `fulcrum_id`
      await clonedConstructionRecords.value.forEach((record) => {
        if (record.fulcrum_id === null) {
          record.create();
        }
      });

      // check if there are construction records that need to be removed from
      // the system. If so, transform the data and then hit the `delete` service.
      if (itemsForRemoval.value.length > 0) {
        let removeConstructionRecords = itemsForRemoval.value.map(
          (removalItem) => ({
            fulcrum_id: removalItem.fulcrum_id ? removalItem.fulcrum_id : null,
            project_record: removalItem.project_record
              ? removalItem.project_record
              : null,
            permit_record: removalItem.permit_record,
            project_num: removalItem.project_num
              ? removalItem.project_num
              : null,
            project_name: removalItem.project_name
              ? removalItem.project_name
              : null,
            permit_num: removalItem.permit_num,
            permit_desc: removalItem.permit_desc,
            permit_type: removalItem.permit_type.join(),
            status: removalItem.status
          })
        );

        let clonedRemoveConstructionRecords = ref([]);
        removeConstructionRecords.forEach((record) => {
          const constructionRecord = new ConstructionRecord({});
          const constructionRecordClone = constructionRecord.clone();
          Object.assign(constructionRecordClone, record);
          clonedRemoveConstructionRecords.value.push(constructionRecordClone);
        });

        await clonedRemoveConstructionRecords.value.forEach((record) => {
          if (record.fulcrum_id !== null) {
            store.dispatch("construction-records/remove", record.fulcrum_id);
          }
        });
      }

      snackbar({
        text: `Successfully updated permits associated with this project.`,
        color: "success",
        timeout: 3000
      });
    } catch (error) {
      snackbar({
        text: `Error encountered while trying to update associated permits. Please contact your Administrator.`,
        color: "error"
      });
    } finally {
      loading.value = false;
      itemsForRemoval.value = [];
    }
  };
  const closeAssignPermitDialog = (isEditDialogOpen) => {
    isPermitListEditDialogOpen.value = isEditDialogOpen;
  };
  return {
    loading,
    isPermitListEditDialogOpen,
    isUpdatingPermitAssignment,
    selectedPermits,
    tableHeaders,
    editItems,
    itemsToEdit,
    updateSelectedConstructionRecords,
    removeConstructionRecord,
    //assignPermit,
    processFormData,
    closeAssignPermitDialog
  };
}

/**
 * Load Estimate Items for Current Construction Record
 * @description When a user selects a construction record for Traffic Statement
 * item editing, we need to check for the presence of estimate items
 * in the `estimate_item` table. If we find at least 1 record for the
 * project_id/const_id combination then the `Load Estimate Items` dialog stays
 * hidden. If we don't find anything the short list items are copied over to
 * estimate items table.
 *
 * @returns
 */
export function useLoadRecordEstimateItemsDialog() {
  const { router } = useRouter();
  const isLoadEstimateItemsDialogOpen = ref(true);

  const processFormData = async (form) => {
    try {
      const initialEstimateItem = new EstimateItem({
        project_id: form.projectId,
        const_id: form.constId
      });

      const initialEstimateItemClone = initialEstimateItem.clone();
      initialEstimateItemClone.save().then(() => {
        const constructionRecord = ConstructionRecord.getFromStore(
          form.constId
        );
        constructionRecord.status = "TS In Progress";
        store.dispatch("construction-records/patch", [
          form.constId,
          constructionRecord,
          {}
        ]);
        snackbar({
          text: `Items successfully loaded! Reloading view...`,
          color: "success",
          timeout: 3000
        });
        store.dispatch("estimate-items/updateLoading", false);
      });
      isLoadEstimateItemsDialogOpen.value = false;
    } catch {
      snackbar({
        text: `Error trying to load items. Please contact your Administrator.`,
        color: "error",
        timeout: 3000
      });
    } finally {
      setTimeout(() => {
        window.location.reload();
      }, 3500);
    }
  };

  const closeLoadRecordEstimateItemsDialog = (isDialogOpen) => {
    isLoadEstimateItemsDialogOpen.value = isDialogOpen;
  };
  const closeLoadRecordEstimateItemsDialogAndRedirect = (dialogCloser) => {
    isLoadEstimateItemsDialogOpen.value =
      dialogCloser.isLoadEstimateItemsDialogOpen;
    router.push({
      name: "apps-project-view",
      params: {
        project: dialogCloser.project
      }
    });
  };
  return {
    isLoadEstimateItemsDialogOpen,
    processFormData,
    closeLoadRecordEstimateItemsDialog,
    closeLoadRecordEstimateItemsDialogAndRedirect
  };
}

/**
 * Add New Estimate Item
 * @description Adds a new estimate item to the list of estimate items
 * for a given construction record (permit).
 */
export function useEditConstructionRecord() {
  const getNewEstimateItemClone = () => {
    let estimateItem = new EstimateItem({});
    return estimateItem.clone();
  };
  return {
    getNewEstimateItemClone
  };
}

/**
 * Update Estimate Items List
 * @description Updates the estimate items list that was pulled into the UI.
 */
export function useUpdateEstimateItemsList() {
  const tableHeaders = [
    { text: "ITEM NUMBER", value: "item_num", sortable: true, align: "start" },
    { text: "DESCRIPTION", value: "item_desc", sortable: true, align: "start" },
    { text: "UNIT COST", value: "item_cost", sortable: true, align: "end" },
    { text: "UOM", value: "item_unit", sortable: true, align: "start" },
    { text: "QTY", value: "item_qty", sortable: true, align: "end" },
    { text: "TOTAL", value: "total", sortable: true, align: "end" },
    {
      text: "ACTIONS",
      value: "actions",
      align: "center",
      sortable: false
    }
  ];

  const processSelectedItems = async () => {
    try {
      const selectedItemsList =
        store.getters["estimate-items/getSelectedItemsList"];
      const fulcrumRemovalItemsList =
        store.getters["estimate-items/getFulcrumRemovalItemsList"];

      await fulcrumRemovalItemsList.forEach((item) => {
        store.dispatch("estimate-items/remove", item);
      });
      await selectedItemsList.forEach((item) => {
        if (item.fulcrum_id !== null && item.fulcrum_id !== undefined) {
          // https://vuex.feathersjs.com/service-plugin.html#update-paramarray
          store.dispatch("estimate-items/patch", [item.fulcrum_id, item, {}]);
        } else if (item.fulcrum_id == undefined) {
          store.dispatch("estimate-items/create", item);
        }
      });
    } catch (error) {
      snackbar({
        text: `Error encountered while trying to update associated permits. Please contact your Administrator.`,
        color: "error"
      });
    }
  };

  const updateConstructionRecordStatus = async (constId) => {
    try {
      const constructionRecord = ConstructionRecord.getFromStore(constId);
      constructionRecord.status = "TS Complete";
      store.dispatch("construction-records/patch", [
        constId,
        constructionRecord,
        {}
      ]);
      snackbar({
        text: `Items successfully loaded! Reloading view...`,
        color: "success",
        timeout: 3000
      });
      store.dispatch("estimate-items/updateLoading", false);
    } catch (error) {
      snackbar({
        text: `Error encountered while trying to update permit status. Please contact your Administrator.`,
        color: "error"
      });
    }
  };

  return {
    tableHeaders,
    processSelectedItems,
    updateConstructionRecordStatus
  };
}

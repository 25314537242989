import { computed, ref } from "@vue/composition-api";
import { useFind, useGet } from "feathers-vuex";
import Vue from "vue";

import { useRouter } from "@/helpers";
import { store } from "@/store";
const { ConstructionRecord, DailyLog, Project } = Vue.$FeathersVuex.api;

// Used to display a list of IDRs. By default we limit the attributes returned
export function useIdrListbyId() {
  const { route } = useRouter();
  //console.log(route);
  const dailyLogsParams = computed(() => {
    let q = {
      query: {
        $limit: store.state["daily-logs"].pageLimit,
        $skip: store.state["daily-logs"].pageSkip,
        $select: [
          "fulcrum_id",
          "idr_date",
          "weather",
          "inspector_first_name",
          "inspector_last_name",
          "work_delay",
          "delay_reason",
          "idr_photo"
        ],
        const_id: route.value.params.record,
        $sort: {
          idr_date: -1
        }
      }
    };
    let $and = [];

    if (store.state["daily-logs"].filterIdrDates.length > 0) {
      $and.push({
        idr_date: {
          $and: [
            { $gte: store.state["daily-logs"].filterIdrDates[0] },
            { $lte: store.state["daily-logs"].filterIdrDates[1] }
          ]
        }
      });
    }
    Object.assign(q.query, { $and });
    return q;
  });

  const {
    items: dailyLogs,
    haveLoaded: dailyLogsHaveLoaded,
    isPending: dailyLogsArePending,
    paginationData,
    latestQuery
  } = useFind({
    model: DailyLog,
    params: dailyLogsParams,
    paginate: true,
    qid: "dailyLogsList"
  });

  return {
    dailyLogs,
    dailyLogsHaveLoaded,
    dailyLogsArePending,
    paginationData,
    latestQuery
  };
}

export function getDailyLogCount() {
  const dailyLogCount = ref(0);

  try {
    let params = { query: {} };
    store.dispatch("daily-logs/count", params).then((response) => {
      dailyLogCount.value = response;
    });
  } catch (error) {
    console.log(error);
  }

  return {
    dailyLogCount
  };
}

export function useIdrDatatable() {
  const headers = [
    { text: "IDR DATE", value: "idr_date", sortable: false },
    { text: "WEATHER", value: "weather", sortable: false },
    { text: "INSPECTOR", value: "inspector_full_name", sortable: false }
  ];
  return { headers };
}

export function useIdr() {
  const { route } = useRouter();
  const fulcrumToken = process.env.VUE_APP_FULCRUM_API_TOKEN;
  const overlay = ref(false);

  // Create computed properties for IDs
  const idrId = computed(() => route.value.params.idr);
  const projectId = computed(() => route.value.params.project);
  const recordId = computed(() => route.value.params.record);

  // Get project data
  const { item: project, hasLoaded: projectHasLoaded } = useGet({
    model: Project,
    id: projectId,
    params: {
      $graphql: {
        designer_nm: true,
        project_phase: true,
        contractor_nm: true,
        design_firm_nm: true,
        inspector_nm: true,
        contract_num: true,
        budget_remaining: true,
        site_record: true
      }
    }
  });

  // Get construction record data
  const { item: constructionRecord, hasLoaded: constructionRecordHasLoaded } =
    useGet({
      model: ConstructionRecord,
      id: recordId,
      params: {
        query: {},
        $graphql: {
          permit_num: true
        }
      }
    });

  // Get IDR data
  const dailyLogParams = computed(() => ({
    query: {},
    $graphql: {
      project_num: false,
      permit_num: true,
      logged_items: true,
      inspector_org_name: true,
      inspector_org_record: true,
      personnel_items: true,
      equipment_items: true,
      contractor_stats: true
    }
  }));

  const {
    item: dailyLog,
    hasLoaded: dailyLogHasLoaded,
    isPending: dailyLogIsPending
  } = useGet({
    model: DailyLog,
    id: idrId,
    params: dailyLogParams
  });

  // Computed property to check if everything is loaded
  const isLoaded = computed(
    () =>
      projectHasLoaded.value &&
      constructionRecordHasLoaded.value &&
      dailyLogHasLoaded.value &&
      project.value !== null &&
      constructionRecord.value !== null &&
      dailyLog.value !== null
  );

  // Photo helper functions
  const resolveThumbnailPhotoById = (id) => {
    return `https://web.fulcrumapp.com/api/v2/photos/${id}/thumbnail.jpg?token=${fulcrumToken}`;
  };

  const resolveLargePhotoById = (id) => {
    return `https://web.fulcrumapp.com/api/v2/photos/${id}/large.jpg?token=${fulcrumToken}`;
  };

  const resolvedPhotos = (photos) => {
    return photos.map(
      (photo) =>
        `https://web.fulcrumapp.com/api/v2/photos/${photo}/large.jpg?token=${fulcrumToken}`
    );
  };

  return {
    overlay,
    project,
    projectHasLoaded,
    constructionRecord,
    constructionRecordHasLoaded,
    dailyLog,
    dailyLogHasLoaded,
    dailyLogIsPending,
    isLoaded,
    resolveThumbnailPhotoById,
    resolveLargePhotoById,
    resolvedPhotos
  };
}

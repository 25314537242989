// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";
import hideTestData from "../hooks/hide-test-data";

class Contract extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Contract";
  static idField = "fulcrum_id";
  static instanceDefaults() {
    return {
      fulcrum_id: null,
      created_duration: null,
      updated_duration: null,
      edited_duration: null,
      created_at: null,
      updated_at: null,
      status: null,
      version: null,
      client_created_at: null,
      client_updated_at: null,
      created_by: null,
      created_by_id: null,
      updated_by: null,
      updated_by_id: null,
      form_id: null,
      contract_num: null,
      contract_desc: null,
      contract_type: null,
      start_date: null,
      end_date: null,
      contract_amount: null,
      project_fulcrum_id: null
    };
  }
}

const servicePath = "contracts";

const servicePlugin = makeServicePlugin({
  debug: true,
  Model: Contract,
  idField: "fulcrum_id",
  service: feathersClient.service(servicePath),
  servicePath,
  extend: () => {
    return {
      state: {
        pageSkip: 0,
        pageLimit: 25,
        sortField: null,
        sortOrder: 1,
        isUpdatingPagination: false,
        isFilteringText: false,
        filterText: null,
        isFilteringContractStatus: false,

        // initial value = all possible values
        contractStatus: ["Active", "Closed"],
        isFilteringContractType: false,

        // initial value = all possible values
        contractType: ["Construction", "Maintenance"]
      },
      mutations: {
        setUpdatePaginationEnter: (state) => {
          state.isUpdatingPagination = true;
        },
        unsetUpdatePaginationEnter: (state) => {
          state.isUpdatingPagination = false;
        },
        setUpdatePagination: (state, payload) => {
          state.sortField = null;
          state.pageSkip = (payload.page - 1) * state.pageLimit;
          state.pageLimit = payload.itemsPerPage;
          state.sortField =
            payload.sortBy.length > 0 ? payload.sortBy[0] : null;
          state.sortDesc = payload.sortDesc[0] == true ? -1 : 1;
        },
        setFilterTextEnter: (state) => {
          state.isFilteringText = true;
        },
        unsetFilterTextEnter: (state) => {
          state.isFilteringText = false;
        },
        setFilterText: (state, payload) => {
          state.filterText = null;
          state.filterText = payload;
        },
        setFilterContractStatusEnter: (state) => {
          state.isFilteringContractStatus = true;
        },
        unsetFilterContractStatusEnter: (state) => {
          state.isFilteringContractStatus = false;
        },
        setFilterContractStatus: (state, payload) => {
          state.contractStatus = [];
          if (payload === null) {
            state.contractStatus = ["Active", "Closed"];
          } else {
            state.contractStatus.push(payload);
          }
        },
        setFilterContractTypeEnter: (state) => {
          state.isFilteringContractType = true;
        },
        unsetFilterContractTypeEnter: (state) => {
          state.isFilteringContractType = false;
        },
        setFilterContractType: (state, payload) => {
          state.contractType = [];
          if (payload === null) {
            state.contractType = ["Construction", "Maintenance"];
          } else {
            state.contractType.push(payload);
          }
        }
      },
      actions: {
        initializePagination: (context, payload) => {
          context.commit("setUpdatePaginationEnter");
          context.commit("setUpdatePagination", {
            page: 1,
            itemsPerPage: payload.initialItemsPerPage,
            sortBy: payload.initialSortBy || [],
            sortDesc: [false]
          });
          context.commit("unsetUpdatePaginationEnter");
        },
        updatePagination: (context, payload) => {
          context.commit("setUpdatePaginationEnter");
          context.commit("setUpdatePagination", payload);
          context.commit("unsetUpdatePaginationEnter");
        },
        filterText: (context, payload) => {
          context.commit("setFilterTextEnter");
          context.commit("setFilterText", payload);
          context.commit("unsetFilterTextEnter");
        },
        filterContractStatus: (context, payload) => {
          context.commit("setFilterContractStatusEnter");
          context.commit("setFilterContractStatus", payload);
          context.commit("unsetFilterContractStatusEnter");
        },
        filterContractType: (context, payload) => {
          context.commit("setFilterContractTypeEnter");
          context.commit("setFilterContractType", payload);
          context.commit("unsetFilterContractTypeEnter");
        }
      }
    };
  }
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [hideTestData()],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;

<script>
  import { defineComponent } from "@vue/composition-api";

  import { weatherIcon } from "@/helpers/iconTypes.js";

  export default defineComponent({
    props: {
      weather: {
        type: String,
        required: false,
        default: null
      },
      temperature: {
        type: String,
        required: false,
        default: null
      },
      loggedItems: {
        type: Array,
        required: true,
        default: () => []
      },
      idrPhoto: {
        type: Array,
        required: false,
        default: () => []
      },
      icons: {
        type: Object,
        required: true
      }
    },
    setup(props) {
      const hasWeatherData = () => props.weather && props.temperature;

      return {
        weatherIcon,
        hasWeatherData
      };
    }
  });
</script>

<template>
  <v-card-text class="align-start mb-8">
    <div
      class="d-flex"
      :class="hasWeatherData() ? 'justify-space-around' : 'justify-start'"
    >
      <div class="d-flex" style="gap: 96px">
        <!-- Weather - only show if data exists -->
        <div v-if="hasWeatherData()" class="d-flex align-center">
          <v-avatar size="44" color="info" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ weatherIcon(weather) }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">{{ weather }}</p>
            <h3 class="text-xl font-weight-bold">{{ temperature }}°F</h3>
          </div>
        </div>

        <!-- Items -->
        <div class="d-flex align-center">
          <v-avatar size="44" color="warning" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ icons.mdiClipboardPulseOutline }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">Items</p>
            <h3 class="text-xl font-weight-bold">
              {{ loggedItems && loggedItems.length ? loggedItems.length : 0 }}
            </h3>
          </div>
        </div>

        <!-- Photos -->
        <div class="d-flex align-center">
          <v-avatar size="44" color="success" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ icons.mdiImageMultipleOutline }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">Photos</p>
            <h3 class="text-xl font-weight-bold">
              {{ idrPhoto && idrPhoto.length ? idrPhoto.length : 0 }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </v-card-text>
</template>

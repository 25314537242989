// import { computed } from "@vue/composition-api";
import { computed } from "@vue/composition-api";
import { useFind } from "feathers-vuex";
import Vue from "vue";

import { store } from "@/store";
const { Project } = Vue.$FeathersVuex.api;

export function useProjectList() {
  const projectsParams = computed(() => {
    let q = {
      query: {
        $limit: store.state["projects"].pageLimit || 50,
        $skip: store.state["projects"].pageSkip,
        $sort: {
          portal_updated_at: -1
        }
      },
      $graphql: {
        contractor_nm: true,
        design_firm_nm: true,
        inspector_nm: true,
        budget_remaining: true,
        site_record: true
      }
    };
    let $and = [];
    // conditionally add/remove filters
    // if the array has a length of 0 then we do not push it to
    // the `q.query.$and` array.
    // if the array is already in `q.query.$and` and has a length
    // of 0 then we must remove it.
    // if the array has a length > 0 then we push it to the
    // `q.query.$and` array.
    // if the array has a length > 0 and is already in `q.query.$and`
    // ....?
    if (store.state["projects"].filterText !== null) {
      $and = [
        {
          $or: [
            {
              proceed_num: {
                $iLike: store.state["projects"].filterText + "%"
              }
            },
            {
              proceed_name: {
                $iLike: "%" + store.state["projects"].filterText + "%"
              }
            }
          ]
        }
      ];
    }
    if (store.state["projects"].filterContractors.length > 0) {
      $and.push({
        contractor_record: { $in: store.state["projects"].filterContractors }
      });
    }
    if (store.state["projects"].filterDesignFirms.length > 0) {
      $and.push({
        design_firm_record: { $in: store.state["projects"].filterDesignFirms }
      });
    }
    if (store.state["projects"].filterDesigners.length > 0) {
      $and.push({
        designer_record: { $in: store.state["projects"].filterDesigners }
      });
    }
    if (store.state["projects"].filterFiscalYears.length > 0) {
      $and.push({
        fiscal_year: { $in: store.state["projects"].filterFiscalYears }
      });
    }
    if (store.state["projects"].filterLeadInspectors.length > 0) {
      $and.push({
        inspector_record: { $in: store.state["projects"].filterLeadInspectors }
      });
    }
    if (store.state["projects"].filterProjectTypes.length > 0) {
      $and.push({
        project_type: { $in: store.state["projects"].filterProjectTypes }
      });
    }
    if (store.state["projects"].filterProjectStatuses.length > 0) {
      $and.push({
        status: { $in: store.state["projects"].filterProjectStatuses }
      });
    }

    // if (store.state["projects"].sortField !== null) {
    //   q.query.$sort = {
    //     [store.state["projects"].sortField]: store.state["projects"].sortDesc
    //   };
    // }
    Object.assign(q.query, { $and });
    //console.log(q);
    return q;
  });

  const {
    items: projects,
    isPending,
    haveLoaded, // used for first-load of data table
    paginationData,
    latestQuery,
    find: findProjects
  } = useFind({
    model: Project,
    params: projectsParams,
    paginate: true,
    qid: "projectList"
  });

  return {
    projects,
    isPending,
    haveLoaded,
    paginationData,
    latestQuery,
    findProjects,
    projectsParams
  };
}

export function useUserProjectList(userContactId) {
  const userProjectsQuery = computed(() => {
    console.log(userContactId);
    if (!userContactId) {
      return null;
    }
    return {
      query: {
        $select: ["fulcrum_id", "proceed_name", "status", "assign_date"],
        $or: [
          { designer_record: userContactId },
          { contractor_record: userContactId },
          { inspector_record: userContactId }
        ]
      },
      $graphql: {
        withEstimate: true,
        withActual: true
      }
    };
  });

  const {
    items: userProjects,
    haveLoaded: userProjectsLoaded,
    latestQuery: userProjectsLatestQuery
  } = useFind({
    model: Project,
    params: userProjectsQuery,
    qid: "userProjectList"
  });

  return {
    userProjectsLatestQuery,
    userProjects,
    userProjectsLoaded
  };
}

export const handlePageEvent = (event) => {
  console.log(event);
  //store.dispatch("projects/incrementPage", event);
};

export function useProjectDatatable() {
  const headers = [
    { text: "NAME", value: "proceed_name" },
    { text: "STATUS", value: "status" },
    { text: "LEAD INSPECTOR", value: "inspector_nm" },
    { text: "PROJECT TYPE", value: "project_type" },
    { text: "NTP", value: "proceed_date" },
    { text: "REMAINING BUDGET", value: "budget_remaining" }
  ];

  return { headers };
}

<script>
  import { mdiEyeOutline } from "@mdi/js";
  import { defineComponent, ref, watch } from "@vue/composition-api";

  // Components
  import ItemFilterMenu from "@/components/common/filter-menu/ItemFilterMenu.vue";
  import { usePagination } from "@/composables/usePagination";
  import { formatCurrency } from "@/helpers/filter";
  import { store } from "@/store";
  import {
    usePermitFinancialList,
    usePermitItemTrackerDatatable
  } from "@/views/apps/construction-record/construction-record-view/construction-record-tab-financial/useConstructionRecordTabFinancial";
  import EstimateItemQuickView from "@/views/apps/construction-record/construction-record-view/construction-record-tab-item-tracking/EstimateItemQuickView.vue";

  export default defineComponent({
    components: {
      ItemFilterMenu,
      EstimateItemQuickView
    },
    setup() {
      const {
        permitItemFinancials,
        permitItemFinancialsArePending,
        permitItemFinancialsHaveLoaded,
        paginationData,
        latestQuery
      } = usePermitFinancialList();

      const { itemTrackingHeaders } = usePermitItemTrackerDatatable();

      const { options, itemsPerPageOptions } = usePagination({
        initialItemsPerPage: 10,
        initialSortBy: ["item_num"],
        itemsPerPageOptions: [5, 10, 25, 50],
        storePath: "permit-item-financials"
      });

      const resolvePercentRemainColor = (
        estimateQty,
        actualQty,
        prctRemaining
      ) => {
        if (estimateQty == 0 && actualQty > 0) {
          return "error"; // Item was not included in original estimate. Anything logged is automatically over budget.
        } else if (prctRemaining < 0) {
          return "error";
        } else if (prctRemaining >= 0 && prctRemaining <= 30) {
          return "warning";
        } else {
          return "success";
        }
      };

      const actionMenu = ref(false);
      watch(options, (value) => {
        store.dispatch("permit-item-financials/updatePagination", value);
      });

      return {
        formatCurrency,
        permitItemFinancials,
        permitItemFinancialsArePending,
        permitItemFinancialsHaveLoaded,
        paginationData,
        latestQuery,
        itemTrackingHeaders,

        // usePagination
        options,
        itemsPerPageOptions,

        resolvePercentRemainColor,
        actionMenu,
        icons: {
          mdiEyeOutline
        }
      };
    }
  });
</script>

<template>
  <div v-if="permitItemFinancialsHaveLoaded">
    <v-card flat id="invoice-list">
      <v-card-text class="d-flex align-center justify-space-between flex-wrap">
        <div class="d-flex title">Item Tracking</div>
        <div class="d-flex">
          <item-filter-menu />
        </div>
      </v-card-text>

      <!-- data table -->
      <v-data-table
        v-if="latestQuery"
        :headers="itemTrackingHeaders"
        :items="latestQuery.response.data"
        :options.sync="options"
        :server-items-length="latestQuery.response.total"
        :loading="permitItemFinancialsArePending"
        loading-text="Loading... Please wait"
        item-key="estimate_item_id"
        disable-sort
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25, 50]
        }"
      >
        <!-- item number -->
        <template #[`item.item_num`]="{ item }">
          <div class="d-flex align-center">
            <div v-if="item.item_num" class="d-flex flex-column">
              <span
                class="black--text font-weight-medium"
                style="max-width: 275px"
                >{{ item.item_desc }}</span
              >

              <small class="black--text font-weight-light"
                >#{{ item.item_num }}</small
              >
            </div>
          </div>
        </template>

        <!-- item quantities -->
        <template #[`item.actual_qty`]="{ item }">
          <span
            :class="
              item.remaining_qty >= 0
                ? 'black--text font-weight-medium'
                : 'error--text font-weight-medium'
            "
          >
            {{ Number.parseFloat(item.actual_qty).toFixed(2) }}
          </span>
          / {{ Number.parseFloat(item.estimate_qty).toFixed(2) }}
        </template>

        <!-- item cost -->
        <template #[`item.actual_cost`]="{ item }">
          <span
            :class="
              item.remaining_cost < 0
                ? 'error--text font-weight-medium mb-1'
                : 'black--text font-weight-medium mb-1'
            "
          >
            {{ formatCurrency(item.actual_cost) }}
          </span>
          <v-progress-linear
            :value="item.remaining_percent"
            :color="
              resolvePercentRemainColor(
                item.estimate_qty,
                item.actual_qty,
                item.remaining_percent
              )
            "
            rounded
          >
          </v-progress-linear>
        </template>

        <!-- card actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu :close-on-content-click="false" offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon x-small v-bind="attrs" v-on="on">
                <v-icon color="primary">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <v-card dark>
              <v-card-text>
                <estimate-item-quick-view :itemData="item" />
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<style></style>

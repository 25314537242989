<script>
  import {
    mdiChevronLeft,
    mdiEyeOffOutline,
    mdiEyeOutline,
    mdiLockOutline
  } from "@mdi/js";
  import { defineComponent, ref } from "@vue/composition-api";
  // Using VeeValidate for validations
  import { ValidationObserver, ValidationProvider } from "vee-validate";

  import themeConfig from "@/themeConfig";
  import { useResetUserPassword } from "@/views/apps/user/useUser";

  export default defineComponent({
    name: "account-reset-password",
    props: {
      token: {
        type: String,
        default: null
      }
    },
    components: {
      ValidationProvider,
      ValidationObserver
    },
    setup() {
      const form = ref(null);
      const {
        passwordResetForm,
        isPasswordVisible,
        isConfirmPasswordVisible,
        loading,
        showPwnWarning,
        onSubmit,
        resetForm
      } = useResetUserPassword();

      const handleFormSubmit = async (passwordResetForm) => {
        onSubmit(passwordResetForm);
        form.value.reset();
      };

      const reset = async () => {
        try {
          resetForm();
        } catch (error) {
          console.error("Unable to clear form");
        } finally {
          form.value.reset();
        }
      };

      return {
        form,
        passwordResetForm,
        isPasswordVisible,
        isConfirmPasswordVisible,
        loading,
        showPwnWarning,
        handleFormSubmit,
        reset,
        resetForm,
        // themeConfig
        appName: themeConfig.app.name,
        appLogo: themeConfig.app.logoDark,

        icons: {
          mdiChevronLeft,
          mdiEyeOutline,
          mdiEyeOffOutline,
          mdiLockOutline
        }
      };
    }
  });
</script>

<template>
  <validation-observer ref="form" v-slot="{ invalid, reset }">
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card class="auth-card">
          <!-- logo and title -->
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link to="/account/login" class="d-flex align-center">
              <v-img
                :src="appLogo"
                max-height="30px"
                max-width="30px"
                alt="logo"
                contain
                class="me-3"
              ></v-img>

              <h2 class="text-2xl font-weight-semibold">
                {{ appName }}
              </h2>
            </router-link>
          </v-card-title>

          <v-card-text>
            <p
              class="text-xl font-weight-semibold text--primary text-center mb-2"
            >
              Reset Password 🔒
            </p>
            <p class="mb-2">
              Your new password must be different from previously used passwords
            </p>
          </v-card-text>
          <v-card-text>
            <!-- pwnd password alert -->
            <v-alert v-if="showPwnWarning" color="error" text>
              <p class="font-weight-semibold mb-1">Password Not Allowed</p>
              <p class="text-sm mb-3">
                The password you are trying to use has been blacklisted. Please
                submit a different one.
                <router-link to="/pages/faq" class="text-decoration-none mt-3">
                  Learn more.
                </router-link>
              </p>
            </v-alert>

            <form
              @submit.prevent="handleFormSubmit(passwordResetForm)"
              @reset.prevent="reset"
            >
              <!-- login form -->
              <div class="mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  mode="lazy"
                  name="New Password"
                  vid="newPassword"
                  rules="required|regexStrongPassword"
                >
                  <label
                    for="newPassword"
                    class="label-color font-weight-semibold d-block"
                  >
                    New Password
                  </label>
                  <v-text-field
                    id="newPassword"
                    v-model="passwordResetForm.password"
                    dense
                    outlined
                    clearable
                    :type="isPasswordVisible ? 'text' : 'password'"
                    placeholder="············"
                    :prepend-inner-icon="icons.mdiLockOutline"
                    :append-icon="
                      isPasswordVisible
                        ? icons.mdiEyeOffOutline
                        : icons.mdiEyeOutline
                    "
                    :error-messages="errors"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  mode="lazy"
                  name="Confirm Password"
                  vid="confirmPassword"
                  rules="required|confirmPassword:@newPassword"
                >
                  <label
                    for="confirmPassword"
                    class="label-color font-weight-semibold d-block"
                  >
                    Confirm Password
                  </label>
                  <v-text-field
                    id="confirmPassword"
                    v-model="passwordResetForm.confirmPassword"
                    dense
                    outlined
                    clearable
                    :type="isConfirmPasswordVisible ? 'text' : 'password'"
                    placeholder="············"
                    :prepend-inner-icon="icons.mdiLockOutline"
                    :append-icon="
                      isConfirmPasswordVisible
                        ? icons.mdiEyeOffOutline
                        : icons.mdiEyeOutline
                    "
                    required
                    :error-messages="errors"
                    @click:append="
                      isConfirmPasswordVisible = !isConfirmPasswordVisible
                    "
                  ></v-text-field>
                </validation-provider>
              </div>
              <v-btn
                type="submit"
                block
                color="primary"
                :loading="loading"
                :disabled="invalid || loading"
                class="mt-4"
              >
                Set New Password
              </v-btn>
            </form>
          </v-card-text>
          <!-- back to login -->
          <v-card-actions class="d-flex justify-center align-center">
            <router-link
              :to="{ name: 'account-login' }"
              class="d-flex align-center text-sm"
            >
              <v-icon size="24" color="primary">
                {{ icons.mdiChevronLeft }}
              </v-icon>
              <span>Back to login</span>
            </router-link>
          </v-card-actions>
        </v-card>
      </div>

      <!-- background triangle shape  -->
      <img
        class="auth-mask-bg"
        height="190"
        :src="
          require(`@/assets/images/misc/mask-${
            $vuetify.theme.dark ? 'dark' : 'light'
          }.png`)
        "
      />

      <!-- tree -->
      <v-img
        class="auth-tree"
        width="247"
        height="185"
        src="@/assets/images/misc/tree.png"
      ></v-img>

      <!-- tree  -->
      <v-img
        class="auth-tree-3"
        width="377"
        height="289"
        src="@/assets/images/misc/tree-3.png"
      ></v-img>
    </div>
  </validation-observer>
</template>

<style lang="scss">
  @import "@/preset/preset/pages/auth.scss";
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.constructionRecordsHaveLoaded)?_c('div',{staticClass:"user-tab-overview"},[_c('construction-record-missing-data-alert'),_c('v-card',{staticClass:"mb-7",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between flex-wrap"},[_c('div',{staticClass:"d-flex"},[_vm._v("Associated Permits")]),(_vm.projectRecord.project_phase === 'Design')?_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"me-3",attrs:{"color":"primary","x-small":"","outlined":""},on:{"click":function($event){return _vm.editItems(_vm.constructionRecords)}}},[_vm._v(" Assign Permit ")])],1):_vm._e()]),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.latestConstructionRecordsQuery.response.data,"options":_vm.options,"server-items-length":_vm.latestConstructionRecordsQuery.response.total,"loading":_vm.areConstructionRecordsPending,"footer-props":{
        itemsPerPageOptions: [5, 10, 25, 50]
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.permit_num",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.permit_type)?_c('v-avatar',{class:item.permit_type ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.permit_type ? '' : 'primary',"size":"32"}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v(_vm._s(_vm.permitTypeIcon(item.permit_type[0])))])],1):_vm._e(),(item.fulcrum_id)?_c('div',{staticClass:"d-flex flex-column ms-3"},[(_vm.projectRecord.project_phase === 'Construction')?_c('span',[_c('router-link',{staticClass:"primary--text font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{
                  name: 'apps-construction-record-view',
                  params: {
                    project: item.project_record,
                    record: item.fulcrum_id
                  }
                }}},[_vm._v(" "+_vm._s(item.permit_num)+" ")])],1):_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(item.permit_num)+" ")])]):_vm._e()],1)]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",class:((_vm.permitStatusColor(item.status)) + "--text"),attrs:{"small":"","color":_vm.permitStatusColor(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.permit_desc",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.permit_desc)+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [(
            _vm.projectRecord.project_phase === 'Design' &&
            _vm.projectRecord.contractor_record !== null &&
            _vm.projectRecord.short_list_record !== null &&
            _vm.projectRecord.contractor_list_record !== null
          )?_c('router-link',{staticClass:"primary--text font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{
            name: 'apps-construction-record-edit',
            params: {
              project: item.project_record,
              record: item.fulcrum_id
            }
          }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","dense":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentEditOutline)+" ")])],1):(
            (_vm.projectRecord.project_phase === 'Design' &&
              _vm.projectRecord.contractor_record == null) ||
            _vm.projectRecord.short_list_record == null ||
            _vm.projectRecord.contractor_list_record == null
          )?_c('span',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"secondary","dense":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentEditOutline)+" ")])],1):(_vm.projectRecord.project_phase === 'Construction')?_c('span',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"secondary","dense":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentEditOutline)+" ")])],1):_vm._e(),(item.status == 'TS Complete')?_c('span',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success","dense":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckDecagram)+" ")])],1):_vm._e()]}}],null,true)}),_c('construction-record-list-edit',{attrs:{"is-permit-list-edit-dialog-open":_vm.isPermitListEditDialogOpen,"edit-items":_vm.itemsToEdit},on:{"update:isPermitListEditDialogOpen":function($event){_vm.isPermitListEditDialogOpen=$event},"update:is-permit-list-edit-dialog-open":function($event){_vm.isPermitListEditDialogOpen=$event},"save":_vm.processFormData,"update":_vm.updateSelectedConstructionRecords,"remove":_vm.removeConstructionRecord,"close-dialog":_vm.closeAssignPermitDialog}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }